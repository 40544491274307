/* ./src/index.css */
@tailwind base;
@tailwind components;

@font-face {
  font-family: 'Paradiso';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Paradiso.ttf);
}

@font-face {
  font-family: 'Paradiso';
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/ParadisoBold.ttf);
}

@font-face {
  font-family: 'Farmhouse';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Farmhouse.otf);
}

.fadeIn {
  visibility: visible;
  height: auto;
  opacity: 1;
  transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms";
}

.fadeOut {
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms";
}

body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: whitesmoke;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #afc4c0;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 2px solid whitesmoke;  /* creates padding around scroll thumb */
}

input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}

.torontoGlow {
  -webkit-animation: torontoGlow 1s ease-in-out infinite alternate;
  -moz-animation: torontoGlow 1s ease-in-out infinite alternate;
  animation: torontoGlow 1s ease-in-out infinite alternate;
}

@keyframes torontoGlow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

@-webkit-keyframes torontoGlow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

@tailwind utilities;
